import HERO from '../../assets/images/bg.desktop.png';
import HERO_MOBILE from '../../assets/images/bg.mobile.png';
import { Carousel } from '../../components';
import Music from './Music/Music';
import Videos from './Videos/Videos';

export default function Home() {
  return (
    <div className={`mt-[10vh]`}>
      <div id="home">
        {/* <Carousel v={1}> */}
        <div className="w-full h-full rounded-md overflow-hidden">
          <div className="w-full h-full rounded-md bg-gray-300">
            <img
              src={window.innerWidth >= 768 ? HERO : HERO_MOBILE}
              alt="hero"
              className="w-full h-full object-cover"
            />
          </div>
          {/* <a href="!#" className="block rounded-md bg-gray-300 lg:hidden">
              <img
                src={HERO_MOBILE}
                alt="hero"
                className="w-full h-full object-cover"
              />
            </a> */}
        </div>
        {/* </Carousel> */}
      </div>
      <Music />
      <Videos />
      {/* <Merch /> */}
    </div>
  );
}
