import { Field, Form, Formik } from 'formik';
import useSheetSubscribe, {
  error,
  success,
} from '../../hooks/useSheetSubscribe';
import { title } from 'process';

export default function Newsletter() {
  const { submitForm } = useSheetSubscribe();
  return (
    <div>
      <h2 className="uppercase pb-5 text-xl">newsletter</h2>
      <Formik
        initialValues={{
          name: '',
          email: '',
          endpoint:
            'https://script.google.com/macros/s/AKfycbyPulYV9lVkRGdfBZXdCSYu0pywGn4PiHO72VpYhEtCFPgO-NG1kx__tpX0bCAcJ8gIQg/exec',
        }}
        onSubmit={async (values, { resetForm }) => {
          try {
            const submitted = await submitForm(values);

            console.log(submitted);
            if (!submitted) {
              throw new Error();
            }

            resetForm();
            success({ title: 'Thanks for subscribing!' });
          } catch (err) {
            error({
              title: 'Inputs are not submitted',
              text: 'Please try again',
            });
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              className="px-3 py-3 rounded-md md:mr-5 w-full mb-5 lg:w-auto lg:mb-0"
              type="text"
              name="name"
              placeholder="Enter name here"
            />
            <Field
              className="px-3 py-3 rounded-md md:mr-5 mb-5 w-full lg:w-auto lg:mb-0"
              type="email"
              name="email"
              placeholder="Enter email here"
            />
            <button className="text-white bg-transparent border-[.2vh] border-white rounded-md px-10 py-3 uppercase w-full hover:bg-white hover:text-black lg:w-auto lg:mb-0">
              {isSubmitting ? 'Submitting...' : 'subscribe'}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
