import {
  faApple,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faTiktok,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Newsletter from '../Newsletter/Newsletter';

export default function Footer() {
  return (
    <div>
      <div className="flex flex-col justify-between py-10 border-b lg:flex-row">
        <Newsletter />
        <div className="lg:border-l pl-0 mt-5 lg:mt-0 lg:pl-10 flex flex-row justify-between items-center lg:flex-col">
          <h2 className="uppercase pb:0 lg:pb-5 text-xs sm:text-sm md:text-lg lg:text-xl">
            follow me
          </h2>
          <ul className="flex flex-row text-lg lg:text-2xl">
            <li className="mr-1 lg:mr-5">
              <a href="https://www.instagram.com/zyahbelle/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li className="mr-1 lg:mr-5">
              <a href="https://x.com/zyahbelle">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
            <li className="mr-1 lg:mr-5">
              <a href="https://www.tiktok.com/@zyahbelle">
                <FontAwesomeIcon icon={faTiktok} />
              </a>
            </li>
            <li className="mr-1 lg:mr-5">
              <a href="https://www.youtube.com/zyahbelle">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="py-5 flex flex-row justify-center text-xxs lg:text-sm">
        <span>&copy; Zyah Belle</span>
        {/* <a href="!#">Terms and Condiitions</a> */}
      </div>
    </div>
  );
}
