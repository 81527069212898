import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  StyledLink,
  StyledWrapper,
  StyledImg,
  StyledIcon,
} from './Styled.components';

export interface SlideItemProps {
  img: string;
  bigText: string;
  smallText?: string;
  to: string;
  showPlayIcon?: boolean;
}

export default function SlideItem({
  img,
  bigText,
  smallText,
  to,
  showPlayIcon,
}: SlideItemProps) {
  return (
    <StyledLink href={to} className="px-[1vw]">
      <StyledWrapper className="rounded-xl relative overflow-hidden">
        <StyledImg
          src={img}
          alt="sample showcase"
          className="rounded-xl z-0 w-full object-cover"
        />
        {showPlayIcon && (
          <StyledIcon className="hover:bg-primary text-white bg-gray-100 w-8 h-8 bg-opacity-50 flex justify-center items-center text-lg absolute z-10 bottom-3 left-3 rounded-full opacity-0">
            <FontAwesomeIcon icon={faPlay} />
          </StyledIcon>
        )}
      </StyledWrapper>
      <h4 className="bold mt-3 whitespace-nowrap text-ellipsis w-full block overflow-hidden">
        {bigText}
      </h4>
      <span className="text-gray-600 text-xs leading-0">
        {!!smallText && smallText}
      </span>
    </StyledLink>
  );
}
