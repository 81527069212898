import { Variants } from 'framer-motion';

export const slideDown: Variants = {
  hidden: {
    opacity: 0,
    y: '-100%',
    zIndex: 0,
  },
  visible: {
    opacity: 100,
    y: '0%',
    zIndex: 1000,
    transition: {
      duration: 1,
    },
  },
};
