import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import { rootReducer } from './features';

const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware().prepend(thunk),
});

export const dispatch = (action: any) => {
  Store.dispatch(action);
};
export type RootState = ReturnType<typeof Store.getState>;
export default Store;
