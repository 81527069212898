import styled from 'styled-components';

// const primary = '#342FE8';

export const StyledIcon = styled.div`
  transform: scale(1.2);
  opacity: 0;
`;

export const StyledImg = styled.img`
  transition: all 500ms ease;
`;

export const StyledLink = styled.a`
  cursor: pointer;
  display: block;
`;

export const StyledWrapper = styled.div`
  :hover {
    ${StyledIcon} {
      opacity: 1;
    }

    ${StyledImg} {
      transform: scale(1.2);
    }
  }
`;
