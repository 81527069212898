import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './app/index';
import { Navigation } from './components';
import Footer from './components/Footer/Footer';
import AppRoutes from './routes/AppRoutes';

function App() {
  return (
    <div className="App px-[5vw] lg:px-24 ">
      <Router>
        <Navigation />
        <Routes>
          <Route path="*" element={<AppRoutes />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
