export interface RouteTypes {
  name: string;
  to?: string;
  linked?: string;
  smooth?: boolean;
  isAnchor?: boolean;
  dropdown?: RouteTypes[];
  self?: string;
  nextTo?: string;
}

export const data: RouteTypes[] = [
  {
    name: 'home',
    smooth: true,
  },
  {
    name: 'music',
    smooth: true,
  },
  {
    name: 'videos',
    smooth: true,
  },
];
