import Swal, { SweetAlertOptions } from 'sweetalert2';
import $ from 'jquery';

export function success({ ...props }: SweetAlertOptions) {
  Swal.fire({
    icon: 'success',
    ...props,
  });
}

export function error({ ...props }: SweetAlertOptions) {
  console.log('error');
  Swal.fire({
    icon: 'error',
    titleText: 'Error!',
    ...props,
  });
}

interface SubmitFormProps {
  endpoint: string;
}

const useSheetSubscribe = () => {
  async function submitForm(values: SubmitFormProps | any) {
    const usersIP = await getUsersIp();

    if (!values.email || !values.name) {
      return false;
    }

    const submitted = await $.ajax(values.endpoint, {
      data: {
        date: getCurrentDate(),
        ip: usersIP.length > 1 ? usersIP : '0.0.0.0',
        ...values,
      },
    });

    return submitted.result === 'success';
  }

  function getCurrentDate() {
    const date = new Date().toLocaleDateString('en-US', {
      timeZone: 'America/New_York',
    });
    const time = new Date().toLocaleTimeString('en-US', {
      timeZone: 'America/New_York',
    });
    return `${date} - ${time} (GMT-4)`;
  }

  async function getUsersIp() {
    const ip = fetch('https://api.ipify.org/?format=json')
      .then((res) => res.json())
      .catch((err) => "Can't find IP");

    return ip;
  }

  return { submitForm };
};

export default useSheetSubscribe;
