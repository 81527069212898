import { useSelector } from "react-redux";
import { RootState } from "../Store";

export const useFeatureSelector = () => {
  const selector = useSelector((state: RootState) => state);
  return selector;
};

export const useGlobalSelector = () => {
  const selector = useSelector((state: RootState) => state.Global);

  return selector;
};
