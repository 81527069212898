import { Link } from 'react-router-dom';
import { useGlobalSelector } from '../../features/useSelector';
import { RouteTypes } from './data';
import { Variants, motion } from 'framer-motion';
import useGlobalService from '../../hooks/useGlobalService';

const expand: Variants = {
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  visible: {
    opacity: 1,
    pointerEvents: 'auto',
  },
};

interface DropdownProps {
  data: RouteTypes[];
  menuId: number;
}

const Dropdown = ({ data, menuId }: DropdownProps) => {
  const { setIsMenuActive, setDropdownId } = useGlobalService();
  const { dropdownId } = useGlobalSelector();

  const Items = data.map((item, idx) => (
    <div key={idx}>
      <Link
        to={item.to ? item.to : ''}
        onClick={() => {
          setDropdownId(-1);
          setIsMenuActive(false);
        }}
        className={`whitespace-nowrap text-dark hover:text-dark hover:text-opacity-70 py-[1vh] px-[2vh] block`}
        style={{ textShadow: 'none' }}
      >
        {item.name}
      </Link>
    </div>
  ));
  return (
    <motion.div
      variants={expand}
      initial="hidden"
      animate={menuId === dropdownId ? 'visible' : 'hidden'}
      className={`absolute top-[100%] py-[1vh] lg:top-[160%] left-[50%] -translate-x-1/2 z-10 w-auto overflow-y-scroll h-[30vh] md:h-auto bg-white rounded-sm lg:py-[2vh] shadow-sm`}
    >
      <div className="flex flex-col justify-start text-center items-center">
        {Items}
      </div>
    </motion.div>
  );
};

export default Dropdown;
