import { Carousel, SlideItem } from '../../../components';
import NOTHING from '../../../assets/images/nothingisideal.jpg';
import GIVE from '../../../assets/images/giveortake.jpg';
import TRIX from '../../../assets/images/trix-rmx.jpg';
import GRAE from '../../../assets/images/graeceilings.jpg';
import Slider from 'react-slick';
import { SlideItemProps } from '../../../components/SlideItem/SlideItem';

const data: SlideItemProps[] = [
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/0bc0e7e9-44d9-4b6f-bf51-161e8ff9e967/artwork-440x440.jpg',
    bigText: 'Yam Grier',
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/YamGrier',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/21755c1f-1a73-402d-8063-6d6d3e6f816f/artwork-440x440.jpg',
    bigText: "Who's Listening Anyway?",
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/WhosListeningAnyway',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/30d13d69-81f1-4090-8568-3a3bd8f55f95/artwork-440x440.jpg',
    bigText: 'Sugawater',
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/sugawater#music-services',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/c2c9df0b-e362-483c-9880-7cbbe81c31e3/artwork-440x440.jpg',
    bigText: 'DND - Single',
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/ZyahDND',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/6164531b-00a4-47c6-a33a-12ed7dcdb2ed/artwork-440x440.jpg',
    bigText: 'I Think That I Love You (feat. Romderful)',
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/ITTILY',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/3f97eaa9-8610-42c7-8cba-462ad1583691/artwork-440x440.jpg',
    bigText: 'You Got It (feat. Rexx Life Raj)',
    smallText: 'Zyah Belle',
    to: 'https://lnk.to/ZyahYouGotIt',
  },
];

export default function Music() {
  return (
    <div id="music" className="py-10 min-h-screen flex flex-col justify-center">
      <h2 className="uppercase pb-5 text-xl">music</h2>

      <Slider
        className="w-full"
        slidesToShow={4}
        slidesToScroll={1}
        swipe
        arrows
        dots={true}
        responsive={[
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              arrows: false,
            },
          },
        ]}
      >
        {data.map((item, idx) => (
          <SlideItem
            key={idx}
            img={item.img}
            bigText={item.bigText}
            smallText={item.smallText}
            to={item.to}
            showPlayIcon
          />
        ))}
      </Slider>
    </div>
  );
}
